import { isCongressBioguide } from 'utilities/targets';

const BIDEN_PORTRAIT_URL =
  'https://tile.loc.gov/storage-services/service/pnp/ppbd/01200/01261v.jpg';
// const CONGRESS_IMAGE_URL = 'https://bioguide.congress.gov/bioguide/photo';
const CONGRESS_IMAGE_URL = 'https://d1lislj368cc33.cloudfront.net';
const HARRIS_PORTRAIT_URL =
  'https://tile.loc.gov/storage-services/service/pnp/ppbd/01200/01262v.jpg';

const profileImageUrlForBioguideId = ({ bioguideId, officialType }) => {
  if (!bioguideId) {
    return null;
  }

  // President
  if (bioguideId === 'WH000046') {
    return BIDEN_PORTRAIT_URL;
  }

  // Vice President
  if (bioguideId === 'VP000046') {
    return HARRIS_PORTRAIT_URL;
  }

  // Congress
  if (
    officialType === 'rep' ||
    officialType === 'sen' ||
    isCongressBioguide(bioguideId)
  ) {
    return `${CONGRESS_IMAGE_URL}/${bioguideId}.jpg`;
  }

  return null;
};

// Discovery and petitions have slightly different data structures for people.
// This supports having both, but picking the best option.
// Add more as needed.
const conditionallyFormatName = (discoveryPerson, petitionsPerson) => {
  const isUsableDiscoveryPerson =
    discoveryPerson?.first_name && discoveryPerson?.last_name;
  const isUsablePetitionsPerson = petitionsPerson?.fullName;

  if (isUsableDiscoveryPerson) {
    return discoveryPerson.first_name + ' ' + discoveryPerson.last_name;
  } else if (isUsablePetitionsPerson) {
    return petitionsPerson.fullName;
  } else {
    return '';
  }
};

export { profileImageUrlForBioguideId, conditionallyFormatName };
