import { parseFullName } from 'parse-full-name';
import PropTypes from 'prop-types';

import PersonPhotoLoader from './PersonPhotoLoader';
import { profileImageUrlForBioguideId } from 'utilities/people';

const smallPhotoSize = 36;
const largePhotoSize = 64;

const smallPhotoSizeClass = 'w-[36px] h-[36px] leading-[36px]';
const largePhotoSizeClass = 'w-[64px] h-[64px] leading-[64px]';

const PersonPhoto = ({
  bioguideId = '',
  name = '',
  officialType = '',
  size = 'small',
}) => {
  const { first, last } = parseFullName(name);
  const imageUrl = profileImageUrlForBioguideId({ bioguideId, officialType });

  let photoSizeClass;
  let pixelSize;
  switch (size) {
    case 'small':
      photoSizeClass = smallPhotoSizeClass;
      pixelSize = smallPhotoSize;
      break;
    case 'large':
      photoSizeClass = largePhotoSizeClass;
      pixelSize = largePhotoSize;
      break;
    default:
      photoSizeClass = smallPhotoSizeClass;
      pixelSize = smallPhotoSize;
      break;
  }

  const photo = imageUrl ? (
    <PersonPhotoLoader
      imageUrl={imageUrl}
      pixelSize={pixelSize}
      photoSizeClass={photoSizeClass}
      name={name}
    />
  ) : null;

  return (
    <div
      className={`${photoSizeClass} relative overflow-hidden rounded border-2 border-stone-200 dark:border-stone-700`}
    >
      <p
        className={`${photoSizeClass} absolute left-[-2px] top-[-2px] text-center`}
      >
        {first[0]}
        {last[0]}
      </p>
      {photo}
    </div>
  );
};

export default PersonPhoto;

PersonPhoto.propTypes = {
  bioguideId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // We don't always have officialType present
  officialType: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
};
