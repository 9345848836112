import PropTypes from 'prop-types';

import Anchor from 'components/typography/anchor';
import { CampaignTypeList } from 'utilities/propTypes';

import { DATA_TYPES } from 'siteconfig';

import * as commonStyles from './common.module.scss';

const DriveRollup = ({
  driveRollup,
  // NOTE: This must start with a `/` or the link element will treat it as external and break the link
  linkPath,
  type = DATA_TYPES.CANDIDATE,
}) => {
  let subText;
  let title;
  let count;
  let countLabel;
  let stateCode;
  let link; // This might turn into a helper fn or something. Or jsut require caller to supply it

  switch (type) {
    case DATA_TYPES.CANDIDATE:
      subText = driveRollup.officeName;
      title = driveRollup.candidateName;
      count = driveRollup.signCount.toLocaleString();
      countLabel = 'pledges';
      link = linkPath;
      stateCode = driveRollup.stateCode.toLowerCase();
      break;
    default:
      throw new Error(`Unknown content type specified: ${type}`);
  }

  return (
    <div className={commonStyles.base}>
      <Anchor href={link}>
        <div className={`sf-${stateCode}`} />
        <div className={commonStyles.detail}>
          <p className={commonStyles.subject}>{title}</p>
          <p className={commonStyles.startedOn}>
            Running <em>for</em> {subText}
          </p>
        </div>

        <div className={commonStyles.signatureCounts}>
          <div>
            <p className={commonStyles.signCount}>{count}</p>
            <p className={commonStyles.signCountDetail}>{countLabel}</p>
          </div>
        </div>
      </Anchor>
    </div>
  );
};

DriveRollup.propTypes = {
  campaign: CampaignTypeList,
  type: PropTypes.oneOf(Object.values(DATA_TYPES)),
};

export { DriveRollup };
