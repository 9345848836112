import Anchor from 'components/typography/anchor';
import { CampaignTypeDriveList } from 'utilities/propTypes';

import { DATA_TYPES } from 'siteconfig';

import * as commonStyles from './common.module.scss';

const Drive = ({ drive }) => {
  const basePath = DATA_TYPES.DRIVE.basePath;
  const shareCode = drive.shareCode;
  const campaignSignCount = drive.signCount.toLocaleString();
  const campaignSubject = drive.subject;
  const countLabel = 'Pledges';
  const trigger = 'pledge';

  return (
    <div className={commonStyles.base}>
      <Anchor href={`/${basePath}/${shareCode}`}>
        <div className={commonStyles.detail}>
          <p className={commonStyles.subject}>{campaignSubject}</p>

          <p className={commonStyles.startedOn}>
            To {trigger} {shareCode}
          </p>
        </div>

        <div className={commonStyles.signatureCounts}>
          <div>
            <p className={commonStyles.signCount}>{campaignSignCount}</p>
            <p className={commonStyles.signCountDetail}>{countLabel}</p>
          </div>
        </div>
      </Anchor>
    </div>
  );
};

Drive.propTypes = {
  drive: CampaignTypeDriveList.isRequired,
};

export { Drive };
