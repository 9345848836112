/**
 * Get a two-digit padded number
 * @function
 * @param {Number} number - The number to be padded to two places.
 */
const padTwoDigits = (number) => {
  if (!number || number > 9) {
    return String(number);
  }
  return String(number).padStart(2, '0');
};

const english_ordinal_rules = new Intl.PluralRules('en', { type: 'ordinal' });
const suffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
};

/**
 * Convert a number or string represenation of a number into it's ordinal form.
 *
 * @see https://stackoverflow.com/a/57518703
 *
 * @function
 * @param {Number|String} number - The number to be converted to ordinal form.
 * @returns {String} The ordinal form of the number.
 * @example
 * // returns '1st'
 * getOrdinal(1);
 * @example
 * // returns '2nd'
 * getOrdinal(2);
 * @example
 * // returns '3rd'
 * getOrdinal(3);
 * @example
 * // returns '4th'
 * getOrdinal(4);
 */
const getOrdinal = (number) => {
  const num = Number(number);
  if (isNaN(num)) {
    return number;
  }
  const category = english_ordinal_rules.select(num);
  const suffix = suffixes[category];
  return `${num}${suffix}`;
};

export { padTwoDigits, getOrdinal };
