import slugify from 'slugify';

const personPageLink = ({ bioguideId, fullName }) => {
  const link = `/explore/person/${encodeURIComponent(bioguideId)}/${slugify(
    fullName
  )}`;
  return link;
};

export { personPageLink };
