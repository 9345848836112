import { format, subYears, startOfToday, isWithinInterval } from 'date-fns';
import { DateTime } from 'luxon';

// This is largely unused or well-tested. If needed, better mocking of date is imperative.
const getToday = () => {
  const now = DateTime.now();
  now.setZone('America/Juneau');
  return now.toISODate();
};

// This is a UTC date, two days in the future past the provided "now" date.
// This keeps them open until roughtly middle of the day they should be closed, ie. Alaska.
const getClosureDateForDrives = ({ now }) => {
  const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;
  const closureDate = new Date(now.getTime() + twoDaysInMilliseconds);
  return closureDate.toISOString().split('T')[0];
};

const isSameDay = (nowDate, dateDate) => {
  return (
    nowDate.getFullYear() === dateDate.getFullYear() &&
    nowDate.getMonth() === dateDate.getMonth() &&
    nowDate.getDate() === dateDate.getDate()
  );
};

const formatDate = ({ unixTimestamp, now, timeZone }) => {
  const date = new Date(unixTimestamp * 1000);

  if (isSameDay(now, date)) {
    const dt = DateTime.fromJSDate(date);
    return dt.toLocaleString({
      timeZone: timeZone,
      ...DateTime.TIME_SIMPLE,
    });
  } else if (
    isWithinInterval(date, { start: subYears(now, 1), end: startOfToday() })
  ) {
    return format(date, 'M/d');
  } else {
    return format(date, 'M/d/yyyy');
  }
};

export { formatDate, getToday, getClosureDateForDrives };
