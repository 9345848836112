import PropTypes from 'prop-types';

import Anchor from 'components/typography/anchor';
import Img from 'next/image';

import signIcon from 'images/icons/pen-line.svg';
import trendIcon from 'images/icons/trending-up.svg';

import { getStateAPAbbreviation } from 'utilities/states';
import getTargetTypeHumanName from 'utilities/targets';

import { DATA_TYPES } from 'siteconfig';

const Petition = ({ petition, intervalLabel = '7d', idx = 0 }) => {
  const { city, state } = petition;
  const hasState = !!state;
  const hasCity = !!city;

  const basePath = DATA_TYPES.PETITION.basePath;
  const shareCode = petition.petition_code;
  const campaignSignCount = petition.sign_count.toLocaleString();
  const campaignSubject = petition.subject;
  const countLabel = 'Signers';
  const trigger = getTargetTypeHumanName(petition.target);
  let locationDetail = '';

  // Location detail needs to handle city or state filters
  if (hasState && hasCity) {
    locationDetail = ` (${city}, ${getStateAPAbbreviation(
      petition.state
    )} only)`;
  } else if (hasState && !hasCity) {
    locationDetail = ` (${getStateAPAbbreviation(petition.state)} only)`;
  }

  const TrendingGroup = ({ signCountInterval, intervalLabel = '7d' }) => {
    if (signCountInterval) {
      return (
        <div className="flex">
          <Img
            src={trendIcon}
            height="12"
            width="12"
            className="mr-1 inline opacity-20 dark:invert"
            alt="📈"
          />
          <p className="text-xs uppercase tracking-wide">
            <strong className="font-semibold">
              {signCountInterval.toLocaleString()}
            </strong>{' '}
            in last {intervalLabel}
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Anchor
      href={`/${basePath}/${shareCode}`}
      data-test-id={`petition-card-${idx}`}
    >
      <div className="card-clickable flex-col align-middle">
        <TrendingGroup
          signCountInterval={petition?.trending?.sign_count_period}
          intervalLabel={intervalLabel}
        />

        <section className="flex w-full flex-wrap gap-x-6 gap-y-2">
          <div className="grow">
            <p className="min-w-40 font-semibold text-zinc-900 dark:text-zinc-100">
              {campaignSubject}
            </p>
          </div>
          <div className="min-w-16">
            <Img
              src={signIcon}
              height="12"
              width="12"
              className="mr-1 inline opacity-20 dark:invert"
              alt={countLabel}
              title={countLabel}
            />
            <span className="font-semibold text-zinc-900 dark:text-zinc-100">
              {campaignSignCount}
            </span>
          </div>
        </section>

        <p className="text-xs uppercase tracking-wide">
          To {trigger} {locationDetail}
        </p>
      </div>
    </Anchor>
  );
};

Petition.propTypes = {
  petition: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    petition_code: PropTypes.string,
    sign_count: PropTypes.number,
    subject: PropTypes.string,
    trending: PropTypes.shape({
      sign_count_period: PropTypes.number,
    }),
    target: PropTypes.string,
  }),
  intervalLabel: PropTypes.string,
};

export { Petition };
