'use client';

import Img from 'next/image';
import { useState } from 'react';

type PersonPhotoLoaderProps = {
  imageUrl: string;
  pixelSize: number;
  photoSizeClass: string;
  name: string;
};

const PersonPhotoLoader = ({
  imageUrl,
  pixelSize,
  photoSizeClass,
  name,
}: PersonPhotoLoaderProps) => {
  const [visibilityClass, setVisibilityClass] = useState('invisible');

  return (
    <div className={`${photoSizeClass} ${visibilityClass} absolute`}>
      <Img
        height={pixelSize}
        width={pixelSize}
        src={imageUrl}
        alt={`Official profile photo of ${name}`}
        onLoad={() => {
          setVisibilityClass('visible');
        }}
        placeholder="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMUkFDgAQAA3gBWoajFhAAAAABJRU5ErkJggg=="
      />
    </div>
  );
};

export default PersonPhotoLoader;
