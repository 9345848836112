const STATE_POSTAL_CODES = new Set([
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
  'AS',
  'GU',
  'MP',
  'PR',
  'VI',
]);

// For when you need an iterable version of the set
const STATE_POSTAL_CODES_ARRAY = Array.from(STATE_POSTAL_CODES);

const STATE_ABBREVIATIONS_AP = {
  AK: 'Alaska',
  AL: 'Ala.',
  AR: 'Ark.',
  AZ: 'Ariz.',
  CA: 'Calif.',
  CO: 'Colo.',
  CT: 'Conn.',
  DC: 'D.C.',
  DE: 'Del.',
  FL: 'Fla.',
  GA: 'Ga.',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Ill.',
  IN: 'Ind.',
  KS: 'Kan.',
  KY: 'Ky.',
  LA: 'La.',
  MA: 'Mass.',
  MD: 'Md.',
  ME: 'Maine',
  MI: 'Mich.',
  MN: 'Minn.',
  MO: 'Mo.',
  MS: 'Miss.',
  MT: 'Mont.',
  NC: 'N.C.',
  ND: 'N.D.',
  NE: 'Neb.',
  NH: 'N.H.',
  NJ: 'N.J.',
  NM: 'N.M.',
  NV: 'Nev.',
  NY: 'N.Y.',
  OH: 'Ohio',
  OK: 'Okla.',
  OR: 'Ore.',
  PA: 'Pa.',
  RI: 'R.I.',
  SC: 'S.C.',
  SD: 'S.D.',
  TN: 'Tenn.',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Va.',
  VT: 'Vt.',
  WA: 'Wash.',
  WI: 'Wisc.',
  WV: 'W.V.',
  WY: 'Wyo.',
  AS: 'A.S.',
  GU: 'Guam',
  MP: 'M.P.',
  PR: 'P.R.',
  VI: 'V.I.',
};

const STATE_FULL_NAMES = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'Washington, D.C.',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
  AS: 'American Samoa',
  GU: 'Guam',
  MP: 'Northern Mariana Islands',
  PR: 'Puerto Rico',
  VI: 'U.S. Virgin Islands',
};

const getStateAPAbbreviation = (abbreviation) => {
  if (!abbreviation) {
    return '';
  }

  const abbrev = abbreviation.toUpperCase();
  return STATE_ABBREVIATIONS_AP[abbrev] || '';
};

const getStateProperName = (abbreviation) => {
  if (!abbreviation) {
    return '';
  }
  const abbrev = abbreviation.toUpperCase();
  return STATE_FULL_NAMES[abbrev] || '';
};

const isValidStateCode = (stateCode) => {
  if (!stateCode) {
    return false;
  }

  return STATE_POSTAL_CODES.has(stateCode.toUpperCase());
};

export {
  getStateProperName,
  getStateAPAbbreviation,
  isValidStateCode,
  STATE_FULL_NAMES,
  STATE_POSTAL_CODES,
  STATE_POSTAL_CODES_ARRAY,
};
