'use client';

import { useEffect, useState } from 'react';

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const CURRENT_YEAR_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
};

// This helps to render the date client-side, to avoid SSR issues (dates differ between server and client)
const UserDate = ({ date }: { date: string }) => {
  const [formattedDate, setFormattedDate] = useState('');
  useEffect(() => {
    const dateCreated = new Date(date);
    const currentYear = new Date().getFullYear();
    if (dateCreated.getFullYear() === currentYear) {
      setFormattedDate(
        dateCreated.toLocaleDateString('en-us', CURRENT_YEAR_DATE_OPTIONS)
      );
    } else {
      setFormattedDate(dateCreated.toLocaleDateString('en-us', DATE_OPTIONS));
    }
  }, [date]);

  return <>{formattedDate}</>;
};

export { UserDate };
