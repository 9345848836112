import PropTypes from 'prop-types';

import Anchor from 'components/typography/anchor';
import { getOrdinal } from 'utilities/numbers';
import { getShortTargetTypeHumanName } from 'utilities/targets';
import { personPageLink } from 'components/cards/links';
import Img from 'next/image';

import lettersIcon from 'images/icons/inbox.svg';
import pollIcon from 'images/icons/line-chart.svg';

import PersonPhoto from './PersonPhoto';

const PetitionTargetRollup = ({
  fullName,
  bioguideId,
  officialType,
  stateCode,
  districtId,
  deliveryTotal,
  approvalRating,
  // To support the older usage of this component, which doesn't have approval data. Should remove after that use is updated.
  hideApprovalRating,
  idx,
}) => {
  const link = personPageLink({
    bioguideId: bioguideId,
    fullName: fullName,
  });

  const showApprovalRating =
    approvalRating !== undefined &&
    approvalRating !== null &&
    !hideApprovalRating;
  const approvalRatingRounded = Math.round(approvalRating);

  return (
    <Anchor
      href={link}
      data-test-id={`petition-target-rollup-card-${idx}`}
      prefetch={false}
    >
      <div className="card-clickable items-center gap-2">
        <PersonPhoto
          bioguideId={bioguideId}
          name={fullName}
          officialType={officialType}
        />
        <div className="w-full grow sm:w-auto">
          <p className="basis-full font-bold text-zinc-900 dark:text-zinc-100">
            {fullName}
          </p>
          <p className="text-xs uppercase tracking-wider">
            {getShortTargetTypeHumanName(officialType, stateCode)}
            {districtId ? `, ${getOrdinal(districtId)} District` : null}
          </p>
        </div>

        <div className="flex min-w-16 flex-row gap-1">
          <Img
            src={lettersIcon}
            height="16"
            width="16"
            alt="Letters"
            title="Letters"
            className="inline w-4 opacity-20 dark:invert"
          />
          <span className="w-16 text-zinc-900 dark:text-zinc-100">
            {deliveryTotal.toLocaleString()}
          </span>
          <Img
            src={pollIcon}
            height="16"
            width="16"
            alt="Approval Rating"
            title="Approval Rating"
            className="inline w-4 opacity-20 dark:invert"
          />
          {showApprovalRating ? (
            <span className="w-16 text-zinc-900 dark:text-zinc-100">
              {approvalRatingRounded}%
            </span>
          ) : (
            <span className="w-16 text-zinc-900 dark:text-zinc-100">--</span>
          )}
        </div>
      </div>
    </Anchor>
  );
};

PetitionTargetRollup.propTypes = {
  fullName: PropTypes.string.isRequired,
  bioguideId: PropTypes.string.isRequired,
  officialType: PropTypes.string.isRequired,
  stateCode: PropTypes.string.isRequired,
  districtId: PropTypes.string,
  deliveryTotal: PropTypes.number.isRequired,
  approvalRating: PropTypes.number,
};

export { PetitionTargetRollup };
