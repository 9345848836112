import { useEffect, useState } from 'react';
import { formatDate } from 'utilities/dates';
import { OpenLetterTypeList } from 'utilities/propTypes';

import Anchor from 'components/typography/anchor';
import Img from 'next/image';

import verifiedIcon from 'images/icons/badge-check.svg';
import mapIcon from 'images/icons/map-pin.svg';

import { DATA_TYPES } from 'siteconfig';

// Represents a single piece of correspondence, in a list view
const Letter = ({ letter }) => {
  const [formattedDate, setFormattedDate] = useState('');

  // Calculates and displays the time or date after render.
  useEffect(() => {
    setFormattedDate(
      formatDate({
        unixTimestamp: letter.sentTimestamp,
        now: new Date(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
  }, [letter.sentTimestamp]);

  return (
    <Anchor href={`/${DATA_TYPES.LETTER.basePath}/${letter.correlationId}`}>
      <div className="card-clickable">
        <section className="flex basis-full flex-row align-middle text-xs sm:text-sm">
          {letter.verifiedVoter ? (
            <Img
              src={verifiedIcon}
              height="16"
              width="16"
              alt="Verified Voter"
              title="Verified Voter"
              className="mr-1 inline"
            />
          ) : (
            <Img
              src={mapIcon}
              height="16"
              width="16"
              alt="Map Pin"
              className="mr-1 inline opacity-20 dark:invert"
            />
          )}
          <span className="grow">
            <span className="hidden sm:inline">
              From a {letter.verifiedVoter ? 'verified voter' : 'constituent'}{' '}
              in{' '}
            </span>
            {letter.city}, {letter.state}
          </span>
          <span className="text-right">{formattedDate}</span>
        </section>
        {letter.subject ? (
          <p className="basis-full font-bold text-zinc-900 dark:text-zinc-100">
            {letter.subject}
          </p>
        ) : null}
        <p className="line-clamp-3 basis-full text-sm text-zinc-600 sm:line-clamp-2 dark:text-zinc-400">
          {letter.message}
        </p>
      </div>
    </Anchor>
  );
};

Letter.propTypes = {
  letter: OpenLetterTypeList.isRequired,
};

export { Letter };
